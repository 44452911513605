import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Content</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Interaction</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Dropdowns`}</em>{` present a list of options that can be used to filter or sort existing content.`}</p>
    <p>{`They can also be used as menus. Their use as menus can be seen in `}<a parentName="p" {...{
        "href": "/components/tabs"
      }}>{`tabs`}</a>{`. At a smaller screen size, the tabs collapse into a dropdown.`}</p>
    <h3 {...{
      "id": "dropdown-versus-select"
    }}>{`Dropdown versus Select`}</h3>
    <p>{`While the dropdown and select components look similar, they have different functions.`}</p>
    <ul>
      <li parentName="ul">{`Use the dropdown component to filter or sort content on a page.`}</li>
      <li parentName="ul">{`Use the select component inside a form where users are selecting from a list of options and submitting data.`}</li>
    </ul>
    <p>{`Another important difference between the two components is the underlying code. The dropdown component can be styled as needed, while the select component’s appearance will be determined by the browser being used.`}</p>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Dropdown`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User needs to pick one option from a list.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Filter dropdown`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When a list contains more than 25 items, use filtering to help find options from the list. May only be used with dropdown or multi-select dropdown. Cannot be used with inline dropdown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Multi-select dropdown`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User needs to select multiple options from a dropdown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Inline dropdown`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A stylized dropdown that can be formatted to appear inline with other content and allows the user to select one option.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Inline multi-select dropdown`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A stylized dropdown that can be formatted to appear inline with other content and allows the user to select more than one option.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <h4 {...{
      "id": "labels"
    }}>{`Labels`}</h4>
    <p>{`Labels inform users what to expect in the list of dropdown options. Use clear labels for the dropdown trigger so that users understand the purpose. Keep the label short and concise by limiting it to a single line of text.`}</p>
    <h4 {...{
      "id": "dropdown-options"
    }}>{`Dropdown options`}</h4>
    <p>{`Describe the dropdown option succinctly in one line of text. Never use images or icons within a dropdown. Avoid having multiple lines of text in a dropdown, but if text wrapping is necessary, limit it to two lines and an ellipsis (…) for overflow content. We recommend presenting the options in alphabetical order.`}</p>
    <h2 {...{
      "id": "interaction"
    }}>{`Interaction`}</h2>
    <p>{`By default, the dropdown displays a label when closed. An open dropdown appears on click rather than on hover. Open dropdown drawers should appear above all other UI elements. They can be dismissed by clicking outside of the dropdown item or on the parent element.`}</p>
    <p>{`Interactions for `}<em parentName="p">{`inline dropdown`}</em>{` are the same as above, however we currently don’t support an `}<em parentName="p">{`inline filter dropdown`}</em>{` (single or multi-select).`}</p>
    <h3 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h3>
    <p>{`Selecting an item from the dropdown will close the drawer and the selected option will replace the label.`}</p>
    <h3 {...{
      "id": "multi-select-dropdown"
    }}>{`Multi-select dropdown`}</h3>
    <p>{`The label remains persistent within the dropdown. Once the user chooses an option from the list, a number badge will appear to the left of the label containing the total number of selected items. Selected options float to the top of the list in alphanumeric order.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "123.50543478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAYAAAAxFw7TAAAACXBIWXMAAAsSAAALEgHS3X78AAACkUlEQVQ4y62VOY9SURTHH4OJFsYFlEKMiZVTOGNDpDTuY2lhbC1MKEim5hsQE0hIKHEa/QA0EEI0UAJhibKEfd+RnYEgCNf/vXlDkIy+W3iTf87L47zfPfcsF0HYWYQQZiORCHve1ng8lo1GI1mv12M+/X5f4FrZbFbShxvmcDg2kZrN5ltGo/GuyWS643K5VPP5/CoivAbYBeozGAykgdVqVUZtqVS6Xq/Xc81m8xSAAY7bpwLwFEd+RH1g97iBhUJB0Wg0wOqT4XBIptMp02KxIN1u97kIlEsCK5XKBogIwWys8eEc0f2E5ohwDeATbuBWhErAlqvVilBtLwCPuIGpVIrZZDJ5MZ1Ovwf4GJHqW60WE3J6jFPc3t5ccp314r8WKi5wL51Ox6xWq91Xq9U3z/PxeDx8MKvVuonSYDB8slgsH7xe7xu32/3O5/O9RUpex2Ixtkk8HpcGBoNBlhe/36/Ecx1TQ5AzgtwxoSAE715Qn1wuJ12UcrksE8ePts0PLIJqLtEuv6DlZDIhnU7nKfXBb9LARCIhEyNVZDKZLipJsAlTu91mEWKKnombSwPRJgwImAIfgFddAjTFxMwwu9Qu0T6PqQ/eS48eEi6ICZfj+T768UE+nz9AVIfFYvEAOsRml3lvJe4+RKH4+1Cv17NjazSaVyqV6t55PqFQiA9mt9uZRfIv2Ww2j9Pp/BIOh08A+ByNRk/Qhx+Rin0xLdKjd+aEv4Ab+LhPK0uvr9lstrm+cNwjcZbl3EBYBdRE4te1Wm0OMBMqvAaIXV94L+eeFBxRGQgEpvhoMyFUaG6CCXlJfVB9rvtQEJ2vAPQV+gZQCCAmHP87JuWhOCl7/61teHz+cNz9T97V36C/AYfWa/Ye8paKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "multiselect dropdown",
            "title": "multiselect dropdown",
            "src": "/static/9fe94301dbeb98cc04f1381ce89adfe0/fb070/dropdown-usage-1.png",
            "srcSet": ["/static/9fe94301dbeb98cc04f1381ce89adfe0/d6747/dropdown-usage-1.png 288w", "/static/9fe94301dbeb98cc04f1381ce89adfe0/09548/dropdown-usage-1.png 576w", "/static/9fe94301dbeb98cc04f1381ce89adfe0/fb070/dropdown-usage-1.png 1152w", "/static/9fe94301dbeb98cc04f1381ce89adfe0/c3e47/dropdown-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "clear-all"
    }}>{`Clear all`}</h4>
    <p>{`To clear all selected items from a list, hover over the number badge and click the “x” icon next to the value. To help with clarity, a browser tooltip appears when the user hovers over the “x” (or `}<inlineCode parentName="p">{`close`}</inlineCode>{`) icon to indicate the click action results.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.91847826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACj0lEQVQ4y5VUPWhaURS+7/lsaJNoqoEOJe0iSAcXh3ZXcIrFLA4JWQUHlyBpBqfSLs4ugg5uDvGvGk0XQ5UKSXQIqEn8bVAjgr/RgApqz33Jk2d8NvTA4xzPPfe73znfvaLJZIL+ZXg9HA6jUqmEcrkc54eNHSOSJDnBdnZ2KKfTyRsOh2S5XCZ7vR7Z7/fniqdA2I6OjpBMJqPj7e3t1d3dXZFOp3utVqtXF7E+PT3lFQqFpWw2+wLAKJyD+AG4WCyibrdLF9brdRSJRMjHVgUOh2MvkUgcQH6/Uqnst1qtg7u7u712uy3GNZAjAYRgWGLQOfP7/bQfjUbrFovFE41Gf8L8grAhWKvVjpvNprfRaLzFNff39wTDbmbo2EQiEfofg3kit9vNLYpYTHeA+Hw+DxyeCW9jY4MvlUopq9VKAQPq/PycAnbUYDCgnt6MGVGSySSSy+V0rNfrl41Go9BkMgm0Wu1CUWCuvHw+j0VZAjD+jChwKsNuZgTg1+12eygejwdBDG+1WvWBID9AQD+I8u5RRJKZ3xQQG0EQ09PNZjMDuGyz2b78jsWqqXR6cHFx0b26uhr/ubk5hLVXuAaA51vGPxhWcHnpuHJ7Oz3hVyTywevzfXS53fJDl+vT12/fBTifSqUIg8GAOBmyW2Wbx+OhuGaYZW98yjAQCCCJRELHKpVqZXNzc02j0awpFIqXi0Q5DoUIeCm0+vDxGIY0W4yeTqfpwkwmQ8LLoU5OTggQawXEUMOctsBrQBANCLIF/jOsCeEOEriWAZy5h2zaTOvj8fgNzDQOTC5hYwoUTXU6nUt4JQnw79m1c0CgIB1fX1+js7Mz9NxfGgMWi8W4X8pTEwqFzwIqlUrO/F8gT8/RM6lAQAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "clear all on multiselect dropdown",
            "title": "clear all on multiselect dropdown",
            "src": "/static/654ef43794a1c9e7a9b903575082e685/fb070/dropdown-usage-2.png",
            "srcSet": ["/static/654ef43794a1c9e7a9b903575082e685/d6747/dropdown-usage-2.png 288w", "/static/654ef43794a1c9e7a9b903575082e685/09548/dropdown-usage-2.png 576w", "/static/654ef43794a1c9e7a9b903575082e685/fb070/dropdown-usage-2.png 1152w", "/static/654ef43794a1c9e7a9b903575082e685/c3e47/dropdown-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "filter-dropdown"
    }}>{`Filter dropdown`}</h3>
    <p>{`By default, the dropdown displays a label when closed. When the user hovers over the label area, a text cursor will appear. The drawer opens on click (anywhere in the field) and the user can type to filter through the list of options below. Once the user begins typing, the `}<inlineCode parentName="p">{`close`}</inlineCode>{` (x) icon will appear to the right of the label. This will clear any user-inputted text. Selecting an item from the dropdown will close the drawer and the selected option will replace the default label.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "188.72282608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAmCAYAAADEO7urAAAACXBIWXMAAAsSAAALEgHS3X78AAAExElEQVRIx61WS0hrRxiek4fexBRvUtukGkUvSnVTULQqLsSdCIpii4hyb6VIpVt3Llzo0upCsBSLrmy5Wy8XdFFIqZFqfT/x/X7E99ubkMTT75+eE3KSk5u+fvgzk+Gf78z83zf/DNvY2GCpqamMLD8/nxUVFbGcnBxWXFzM2+rqaiaKIpuYmGAUK/v6+rriP5nccsvKymJqRmB7e3u8lV3N6AMB1LS0NN4vKCgwYIWmvLy853AjhgxqkwcHB9nW1pYGIOSCYoX0U19fzwf6+vpiBgYGDJ2dnc+Gh4eNCH5xdnb24ubmJvX6+pr77e1tqsvliltaWtJtbm7GYL5OXp1iy1VVVaFb/fD4+HgVgGd3d3cugLoAdnJ1dXWM/mdSjKC6ZTkv1A4NDfH+9va2dn5+PgkrSd7Z2bFjNXZsMxkTqR8jz5UJUoD+H6ZYYXZ2Nu83NDSYGhsbzbW1tR/B4zGkUZu8uLioxbxYgFAO9Yoc0p/S0lI+0NHRIYAQDrK2tha3srLyNfzbw8PDJtn39/e/OTg4+JhikApNRB2CYd4i8bwFEWaADcN/hw6du7u7TrSjyO0I+p9SDP4HSFGAkVksFoWYo9nR0VEAKIyUhIQE3hoMBq3JZNLLk0ZHR3Xj4+M6bFGHVemwIh3pjk5ORFLGxsZYSUkJ77e0tBiam5vjnU7nM0hEH2l1yJ0WALFETDApvJW3p9frQ7cUPzc39yMYfY3An1ZXV8l/Rk4HQFiaRJxGdctarTYA5HA4eKJxzGxYpR+t+Pj4KD48PPAWZIlIQT7FgHWNqg6DdXR5eSlIbFtP/zIvQN6R39/fu9G6z8/PcykGsVQcmOpZlu3i4oID4rx+AjC/1+sVg+3p6UkEYIEUGy788vJyZrPZeD8xMZGBVQ6ISRbk8S0KxG8oCA6sxoH2V4A4MJZJMScnJxrkUf0s2+32f3R2Q7Ua2G5/fz9LSkri/YyMDC1cn56erqEJWKUepOjRV7jH4xHa2toYtBkubMiAFRYW8gFo0NjU1GTu6uqKA6sRdSgV1TAdKkjJzMxUTEJ+Ppienv5uZmbmB+SpBxrswce/X15e7sH/FIrBWHiBxYQAWGVlJd1ugiQJG77ow7a59mQnXaLicB1Cj9FlI+sQE60AO4X7SH+Se0J1GJU9WYcAJB0++Xw+MdRwzUTWYaghdxwQmnuOLb2Gv0V/EP6GHKt6g1svIzj2vYZLiUkJj11YWKiAf4mxanIUii/g1DcHx77XcKPxr2JbNtx6HlRoESsScTq4gxARMZ9LJETfMsTKATHZiokXqDgE5AWoF9v3oRZSmyvFRgcEg4JUmmyoODc4FQHJgGURY0RKXkRSqCBYrVbepxYFQZAKrBVMn6LqeOHv4G6AuTHmBvu50sfDAbEVxYGXD/3IyIgWIrdPTU2lTE5OJkuegrFkXBv85TA7O8uiCvs/vxyIevnlgFeDsa6uztLe3m7y+/3aSJNp9SArhl4OcOUlBSZZRUUFH+ju7hZ6e3s5EACNEG4tUvIVCHkp+Svk8iU8QT7L2KagWmBramoUhROtGZr7BdKYBBFjOCXj8D/ALPV5NUGJC385ECq9p8laW1tZWVnZ33o5UAy9NkIvuX9teBSojv8JXilqKt5vts8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "filter dropdown interactions",
            "title": "filter dropdown interactions",
            "src": "/static/a25cb6237d728938dead9918c011d9c5/fb070/dropdown-usage-3.png",
            "srcSet": ["/static/a25cb6237d728938dead9918c011d9c5/d6747/dropdown-usage-3.png 288w", "/static/a25cb6237d728938dead9918c011d9c5/09548/dropdown-usage-3.png 576w", "/static/a25cb6237d728938dead9918c011d9c5/fb070/dropdown-usage-3.png 1152w", "/static/a25cb6237d728938dead9918c011d9c5/c3e47/dropdown-usage-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "multi-select-filter-dropdown"
    }}>{`Multi-select filter dropdown`}</h3>
    <p>{`This variation of dropdown follows the same interaction patterns described above with the following exceptions:`}</p>
    <ul>
      <li parentName="ul">{`The label will remain persistent except when the user is inputting text.`}</li>
      <li parentName="ul">{`Selected options will appear at the top of the list.`}</li>
      <li parentName="ul">{`The drawer does not close once the user makes a selection. The user will need to click outside of the dropdown or on the parent element to close the drawer.`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "130.97826086956525%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAAsSAAALEgHS3X78AAADbElEQVRIx5VUSUsjURDu19GZw9gOgkExmegwo3jyLHjz5lEEc1AQQXLxIHr2Ioh3/4Ce/AGeFcQF9424xxiXGLe4JTFqEtuer4p+khhNOwXFq27qfe+rV98rJR6PK+lmGIYyNjamBINBZX9/P6f7/X5eyeR3TltbW1M3NzfVg4MDFRvY9/b21Pd5EpQNG5SamhqO29raCvr6+grAMn9lZSXvs4NwQB5AvoEROee9sT0+PlYGBwc5cW5uTni9XmYQCoW09fX19q2tLQ8SO3Z3dzvAzrOzs9Pu8/l+msyItZAsM5j29PTwen5+Lsy1HJtfT09PjXA4zH57e2scHR2RV1LOycmJyCqZPurr6znu7+8nxpwUCARcAI1eXl7qd3d3yVgsRq5HYVdXVwx4c3OjpjclA1gaGDEggMvPzs5S2G9ACcbT05ORTCaNSCSSAmCVBMwCcDqdSklJCce0Hh4eSoZOlBTAARcoN3R/f09+gbIDYP6bcgAssgBJd+nx7Owsx5OTk+r8/Hzx1NSUHat9aWnJvri4aF9YWCienp62UQ7itzKpy5Y6/Kpl3N329vabDj0eT0Fra2vxwMAAaTEvB0A+2HwnpzhLh7W1tZzY29urjI6OCvN+tOXl5U4IvBu664IOu3B4N3TZiY2sQ/wXssz0Z8hWV1fHKzrLgHjL5UgyIBsDsmFHhw3SJUhUmTpUJdinJmWDbpMOI+jwC7qbeHh4IH+BFunfX8pBx1XLC8aTE+bpFWCrEyvSYSKRMFKpFLHUc+qwtLQ0Q4d0L+bFl+GJeQHsB4APJfvAyA8QLw6qoBxcR24dphtYiY2NDQ1eiOZo0Bx5ITSpzczMqHJS/dc8/KplAI2PjyvV1dUcNzY2am63u6ilpaWooaFB+wwATG14mvmmHjPnIY36tPIFXDXjH8PDw03QnVvX9WZ0uPnx8dGNtQkDo9AcIDYAiQ91KG1oaEiYknAB8BWC5g5DLjxx0KBXyIbHF4BFVsmEOjIywjEGAJUjzOHgwjCI4uJ1dD6FjiehTR2lRjF4K82Xolo2ReqQXgqE/UxTmrSIUpkh4mepw+vra2thy7EOJr8AGgZoHKAxAJHH8WrC0N8fykHpwhIQpfI6MTFhg97K8O2A9hzQogNX4sA1lOE/z0M5Oy0NmyxzVldXP/z/D7IAH1IShkJ0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "multiselect filter dropdown interactions",
            "title": "multiselect filter dropdown interactions",
            "src": "/static/a6049ef2c10a328701fe11b66a45ab90/fb070/dropdown-usage-4.png",
            "srcSet": ["/static/a6049ef2c10a328701fe11b66a45ab90/d6747/dropdown-usage-4.png 288w", "/static/a6049ef2c10a328701fe11b66a45ab90/09548/dropdown-usage-4.png 576w", "/static/a6049ef2c10a328701fe11b66a45ab90/fb070/dropdown-usage-4.png 1152w", "/static/a6049ef2c10a328701fe11b66a45ab90/c3e47/dropdown-usage-4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      